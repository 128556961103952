export function calculateDiscount(price: number, quantity: number) {
    let discount = 0

    if (quantity >= 2 && quantity <= 3) {
        discount = price * 0.05
    } else if (quantity >= 4) {
        discount = price * 0.1
    }

    return discount
}
