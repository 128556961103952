'use client'

import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

export default function CallbackRedirect() {
    const router = useRouter()

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const search = localStorage.getItem('lastPathname')
            if (search) {
                router.push(search)
                localStorage.removeItem('lastPathname')
            }
        }
    }, [router])

    return null
}
