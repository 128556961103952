import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(web)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-inter\",\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(web)/layout.tsx\",\"import\":\"Oswald\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-oswald\"}],\"variableName\":\"oswald\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(web)/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/callback-redirect/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/vercel/path0/src/context/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/vercel/path0/src/context/cart-providers.tsx");
