import { apiVersion, dataset, projectId, token, useCdn } from '../env'
import { createClient } from 'next-sanity'

export const client = createClient({
    projectId,
    dataset,
    apiVersion,
    token,
    useCdn,
    perspective: 'published',
})
